import * as constants from './../constants';
import {
    API_USER_SERVICE_PORT,
    CONTROL_CENTER_API_URL,
} from '../../config/env/env';
import { filterArrayEmptyValue } from '../../utils/helper';
const mockServer = 'https://9e62366d-16e0-4f2b-9c8a-3ebd33d46448.mock.pstmn.io';
const mocking = false;
export const getLeadSourceList = (type, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/static-values/${type}`,
        method: 'GET',
        success: (data) => {
            return {
                type: constants.LEAD_SOURCE_STATIC_VALUES_DATA,
                payload: data.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const createStaff = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaff = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/' + data.id,
        method: 'PUT',
        data: data.value,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const editStaffClient = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data.id}/client-details`,
        method: 'PUT',
        data: data.value,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const fetchConvertStaffList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/hire/getBulkConvert`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
/**export const covertToStaff = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/hire/${id}/bulk-convert`,
        method: 'POST',
        data: {},
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});*/
export const covertToStaff = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/hire/bulk-convert`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        async: true,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        hideNotify: true,
    },
});
export const fetchClientList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/customers`,
        method: 'GET',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const fetchVendorList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/vendors`,
        method: 'GET',
        success: (response) => {
            console.log('fetchVendorList', response);
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editStaffVendor = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data.id}/vendor-details`,
        method: 'PUT',
        data: data.value,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteStaff = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/' + data.id,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getSingleStaff = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/' + id,
        method: 'GET',
        data: {},
        success: (response) => {
            if (response && response.status && response.status.code == 200) {
                if (response.response) {
                    return {
                        type: constants.SET_SINGLE_STAFF_INFO,
                        payload: response.response,
                    };
                }
            }
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const UploadStaffInfo = (
    { uploadType, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/transaction/${uploadType}/upload`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getAllStaffs = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/all',
        method: 'POST',
        data: data,
        success: setStaffData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getV2AllStaffs = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/all/v2',
        method: 'POST',
        data: data,
        success: setStaffData,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllOrders = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `${
            !!mocking ? mockServer : CONTROL_CENTER_API_URL
        }/control_center/v1/orders`,
        method: 'POST',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
    },
});
export const getAllRiders = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `${
            !!mocking ? mockServer : CONTROL_CENTER_API_URL
        }/control_center/v1/riders`,
        method: 'POST',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
    },
});
export const getAllAlerts = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `${
            !!mocking ? mockServer : CONTROL_CENTER_API_URL
        }/control_center/v1/alerts`,
        method: 'POST',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
    },
});
export const updateAlerts = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `${
            !!mocking ? mockServer : CONTROL_CENTER_API_URL
        }/control_center/v1/alert/${data.id}`,
        method: 'PUT',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        thirdParty: true,
    },
});

export const getAllStaffsReport = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/all/report',
        method: 'POST',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getV2AllStaffsReport = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/all/report/v2',
        method: 'POST',
        data: data,
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getHubList = (orgId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        // url: '/org/hubs/list/all',
        url: `/org/hubs/list/${orgId}/all`,
        method: 'GET',
        success: setHubList,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getShiftTimingsList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift/filter',
        method: 'POST',
        data,
        success: setShiftTimingsList,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getStaffTypeList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/types',
        method: 'GET',
        success: setStaffTypeList,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getBaseRateTypeList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/base-rate-types',
        method: 'GET',
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getSalaryTypeList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/staff/salary-types',
        method: 'GET',
        success: () => {
            return { type: constants.DO_NOTHING };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const resetKycDetails = () => {
    return { type: constants.SET_KYC_STAFF_INFO, payload: {} };
};

export const getKycDetails = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/kyc`,
        method: 'GET',
        success: setKycDetails,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getEvDetails = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data?.id}/ev/details`,
        method: 'POST',
        success: setEvDetails,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});

export const updateEvStatus = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/update/ev-request`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const captainApprove = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/ev/captain/approve`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const vendorUpdate = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/update/vendor`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});

export const getStaffEvHistory = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/ev/history`,
        method: 'POST',
        success: setStaffEvHistory,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
const setStaffEvHistory = (data) => {
    if (data && data.response) {
        return {
            type: constants.SET_ALL_STAFF_EV_HISTORY,
            payload: data.response,
        };
    }
};

export const staffKycApproveReject = (
    { staffId, data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/kyc/status`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getStaffBankDetails = ({ staffId, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/bank-details`,
        method: 'GET',
        port: API_USER_SERVICE_PORT,
        data,
        success: (response) => updateStaffBankData(response),
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const postKycDetails = ({ staffId, data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/kyc`,
        method: 'POST',
        port: API_USER_SERVICE_PORT,
        data,
        success: (response) => updateStaffBankData(response),
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const postStaffBankDetails = (
    { staffId, data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/bank-details`,
        method: 'POST',
        port: API_USER_SERVICE_PORT,
        data,
        success: (response) => updateStaffBankData(response),
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

// payslip apis
export const fetchAllPayslip = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslips`,
        method: 'POST',
        success: setStaffPayslip,
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const generatePayslip = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslip/generate`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const savePayslip = (id, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslip`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const autoSignPayslip = (id, payslipId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslip/${payslipId}/sign`,
        method: 'PUT',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const approvePayslip = (
    id,
    { payslipId, ...data },
    onSuccess,
    onError,
) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslip/${payslipId}/approve`,
        method: 'PUT',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
        data,
    },
});
export const deletePayslip = (id, payslipId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${id}/payslip/${payslipId}`,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

const updateStaffBankData = (data, loginFromWeb) => {
    let staffInfo = {
        bankDetails: {},
    };

    // Response to have status 200 for all success cases
    if (data && data.status && data.status.code === 200) {
        if (data && data.response) {
            staffInfo.bankDetails = {
                ...data.response,
            };
        }
    }
    return { type: constants.UPDATE_STAFF_BANK_DETAILS, payload: staffInfo };
};

const setStaffData = (data) => {
    if (data && data.response) {
        return { type: constants.SET_ALL_STAFF_INFO, payload: data.response };
    }
};
const setStaffPayslip = (data) => {
    if (data && data.response) {
        return {
            type: constants.SET_ALL_STAFF_PAYSLIP,
            payload: data.response,
        };
    }
};
const setHubList = (data) => {
    if (data && data.response) {
        const { totalCount, ...payload } = data.response;
        return { type: constants.SET_HUB_LIST, payload };
    }
};
const setShiftTimingsList = (data) => {
    if (data && data.response) {
        return {
            type: constants.SET_SHIFT_TIMINGS_LIST,
            payload: data.response,
        };
    }
};
const setStaffTypeList = (data) => {
    if (data && data.response) {
        return { type: constants.SET_STAFF_TYPE_LIST, payload: data.response };
    }
};
const setKycDetails = (data) => {
    if (data && data.response) {
        return { type: constants.SET_KYC_STAFF_INFO, payload: data.response };
    }
};

const setEvDetails = (data) => {
    if (data && data.response) {
        let response = data?.response ?? {};
        if (
            !!response?.evDetails &&
            response?.evDetails?.evDocuments &&
            response?.evDetails?.evDocuments?.evOthers
        ) {
            // let parsed = JSON.parse(
            //     response?.evDetails?.evDocuments?.evOthers ?? '',
            // );
            let parsed = !!response?.evDetails?.evDocuments?.evOthers
                ? filterArrayEmptyValue(
                      response?.evDetails?.evDocuments?.evOthers
                          .replace(/\[|\]/g, '')
                          .trim()
                          .split(', '),
                  )
                : [];
            response.evDetails = {
                ...response.evDetails,
                evDocuments: {
                    ...response.evDetails.evDocuments,
                    evOthers: !!parsed ? parsed : [],
                },
            };
        }
        if (
            !!response?.evDetails &&
            response?.evDetails?.untagEvDocuments &&
            response?.evDetails?.untagEvDocuments?.evOthers
        ) {
            let parsed = !!response?.evDetails?.untagEvDocuments?.evOthers
                ? filterArrayEmptyValue(
                      response?.evDetails?.untagEvDocuments?.evOthers
                          .replace(/\[|\]/g, '')
                          .trim()
                          .split(', '),
                  )
                : [];
            console.log('the parsed', parsed);

            response.evDetails = {
                ...response.evDetails,
                untagEvDocuments: {
                    ...response.evDetails.untagEvDocuments,
                    evOthers: !!parsed ? parsed : [],
                },
            };
        }
        return { type: constants.SET_STAFF_EV_INFO, payload: data.response };
    }
};

export const sendAppLink = (staffId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/send-onboarding-sms`,
        method: 'PUT',
        port: API_USER_SERVICE_PORT,
        staffId,
        success: (response) => {
            console.log('Response from API', response);
            return { type: constants.DO_NOTHING, payload: response.response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const getRateCard = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/rate-card`,
        method: 'POST',
        port: API_USER_SERVICE_PORT,
        data,
        success: (response) => {
            console.log('Response from API', response);
            return {
                type: constants.SET_RATE_CARD,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const updateStatus = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data.staffId}/update-status`,
        method: 'PUT',
        port: API_USER_SERVICE_PORT,
        data: { status: data.status },
        success: (response) => {
            console.log('Response from update-status', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const BulkUploadStaffs = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/user/upload/staff`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getStaffCityZones = (cityId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/${cityId}/zones`,
        method: 'GET',
        success: (response) => {
            console.log('GetCityZones', response.response);
            return {
                type: constants.SET_STAFF_CITY_ZONES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getchZoneList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/zones`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetchZoneList', response.response);
            return {
                type: constants.SET_STAFF_CITY_ZONES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
