import { combineReducers } from 'redux';
import user from './userReducer';
import staff from './staffReducer';
import attendance from './attendanceReducer';
import payment from './paymentReducer';
import report from './reportReducer';
import settings from './settingsReducer';
import notifications from './notificationReducer';
import mapview from './mapViewReducer';
import lead from './leadReducer';

const rootReducer = combineReducers({
    user,
    staff,
    attendance,
    payment,
    report,
    settings,
    notifications,
    mapview,
    lead,
});

export default rootReducer;
