import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from './Modal';

function CommentModal({ open, onClose, data, onSave, title, ...props }: any) {
    const [isOpen, toggleModal] = useState(open);
    const [comment, setComment] = useState('');
    const [loading, toggleLoading] = useState(false);
    const handleClose = () => {
        // Setting form data to null before closing

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const handleChange = (event: any) => {
        setComment(event.target.value);
    };

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            className={`saveButton`}
            disableElevation
            color={'primary'}
            onClick={async () => {
                await toggleLoading(true);
                onSave && onSave(comment);
            }}
        >
            Save
        </Button>,
    ];
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={data?.name ? `Mark ${data?.name}` : 'Add Comment'}
            actions={actions}
            loading={loading}
            subtitle={null}
            maxWidth={null}
            className={null}
            fullScreen={false}
            isForm={false}
            onSubmit={null}
            subtitleStyle={null}
            withBackDrop={false}
            multiOrgModal={false}
            stickyActions={false}
            payslipPreviewModal={false}
            fullWidth={false}
            containerStyle={{}}
            dialogActionsCenterAligned={false}
        >
            <Box>
                <TextField
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    rows={4}
                    //defaultValue="Default Value"
                    variant="outlined"
                    value={comment}
                    style={{
                        width: '332px',
                    }}
                    onChange={handleChange}
                />
            </Box>
        </Modal>
    );
}

export default CommentModal;
