import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from '../../../components/UI/Spinner/Spinner';
import useSingleEVStyles from './useSingleEVStyles';
import {
    Box,
    Button,
    Divider,
    Grid,
    ListItemIcon,
    Paper,
    Popover,
    Switch,
    Typography,
    withStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BatteryIcon from '../components/BatteryIcon';
import NearMeIcon from '@material-ui/icons/NearMe';
import { SINGLE_EV_ACTION_TYPES } from './helper';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import clsx from 'clsx';
import {
    VerifyKycIcon,
    PaymentSummaryIcon,
    EVVehicleRider,
    EVVehicleMap,
} from '../../../assets/svg';
import Tabbar from '../../../components/Tabbar';
import TabTitle from '../../../components/TabTitle';
import EVTaggingHistory from './EVTaggingHistory';
import RiderMapView from '../../map-view/MapDetailsV3';
import useSingleEVTable from './useSingleEVtable';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import {
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Bar,
    Rectangle,
} from 'recharts';
import {
    blockUnblockEV,
    getEvVehicleHistory,
    getEvVehiclePerformance,
} from '../../../store/actions/settingsActionCreator';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { showMessage } from '../../../utils/helper';
import { openGoogleMaps } from '../helper';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 32,
        height: 18,
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#4CAF50',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#4CAF50',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 18 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
interface SingleEVProps {
    [key: string]: any;
}
const SingleEV: React.FC<SingleEVProps> = ({ ...props }) => {
    const dispatch = useDispatch();

    const dispatchGetAllEVHistory = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getEvVehicleHistory(payload, onSuccess, onError)),
        dispatchBlockUnblockEV = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(blockUnblockEV(payload, onSuccess, onError)),
        dispatchGetPerformance = (
            payload: any,
            onSuccess?: Function,
            onError?: Function,
        ) => dispatch(getEvVehiclePerformance(payload, onSuccess, onError));
    const [loading, setLoading] = useState(false);
    const [evDetails, setEVDetails] = useState<any>(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmData, setConfirmData] = useState<any>(null);
    const [currentRider, setCurrentRider] = useState<any>(null);
    const [tabIndex, setTabIndex] = useState(
        props?.location?.state?.selectedTab ?? 0,
    );
    const [historyData, setHistoryData] = useState<any>({
        evHistoryLineItems: [],
    });
    const [performanceData, setPerformanceData] = useState<any>({});
    const [anchorEl, setAnchorEl] = useState(null);

    const [modalTabIndex, setModalTabIndex] = useState(-1);

    useEffect(() => {
        setEVDetails(props?.location?.state?.payload);
        if (props?.location?.state?.payload?.evId) {
            fetchEVHistoryListData({
                id: props?.location?.state?.payload?.evId,
            });
        }
    }, [props?.location?.state?.payload]);
    useEffect(() => {
        if (evDetails?.evId) {
            fetchEVHistoryListData({
                id: evDetails?.evId,
            });
            fetchEVPerformanceListData({
                evId: evDetails?.evId,
            });
        }
    }, [evDetails]);
    useEffect(() => {
        setCurrentRider(
            historyData?.evHistoryLineItems?.filter(
                (item: any) => !item.returnedDateEpochMillis,
            )?.[0],
        );
    }, [historyData]);
    const onPressMobilize = async (row: any) => {
        if (!!row?.evId && !!row?.remoteControlEnabled) {
            setLoading(true);
            dispatchBlockUnblockEV(
                {
                    evId: row?.evId,
                    action: !!row?.evTelematicInfo?.vehicleIgnitionStatus
                        ? 'immobilize'
                        : 'mobilize',
                },
                (resp: any) => {
                    setLoading(false);
                    showMessage(
                        resp?.status?.message,
                        resp?.status?.code == 200 ? 'success' : 'error',
                    );
                    props?.history.push('/ev-sp', {
                        payload: props?.location?.state?.payload,
                        selectedFilter: props?.location?.state?.selectedFilter,
                    });
                },
                () => {
                    setLoading(false);
                },
            );
        }
    };
    const classes: any = useSingleEVStyles();
    const handlePerformClick = (event: any) => {
        event?.stopPropagation();
        event?.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handlePerformClose = (e: any) => {
        e?.stopPropagation();
        e?.preventDefault();
        setAnchorEl(null);
    };
    const fetchEVHistoryListData = async (payload: any) => {
        if (evDetails?.evId) {
            dispatchGetAllEVHistory(
                {
                    id: evDetails?.evId,
                    ...payload,
                },
                (data: any) => {
                    if (
                        data &&
                        data.status &&
                        data.status.code &&
                        data.status.code == 200
                    ) {
                        if (data && data.response) {
                            setHistoryData(data?.response);
                        }
                    }
                },
                (err: any) => {},
            );
        }
    };
    const fetchEVPerformanceListData = async (payload: any) => {
        dispatchGetPerformance(
            {
                evId: evDetails?.evId,
                startDate: moment()?.subtract(3, 'days')?.format('YYYY-MM-DD'),
                endDate: moment()?.format('YYYY-MM-DD'),
                ...payload,
            },
            (data: any) => {
                if (
                    data &&
                    data.status &&
                    data.status.code &&
                    data.status.code == 200
                ) {
                    if (data && data.response) {
                        setPerformanceData(data?.response);
                    }
                }
            },
            (err: any) => {},
        );
    };
    const open = Boolean(anchorEl);
    const evGoalId = open ? 'simple-popover-ev-performance' : undefined;
    const CARD_ACTION_CTA = [
        {
            text: 'Remote On/Off',
            type: SINGLE_EV_ACTION_TYPES.REMOTE_ON_OFF,
            icon: 'power_settings_new',
            show: evDetails?.remoteControlEnabled,
            component: (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        // onPressMobilize(evDetails);
                        setConfirmModalOpen(true);
                    }}
                    disabled={false}
                    disableElevation
                    classes={{
                        root: clsx(
                            classes.remoteCTA,
                            !!evDetails?.evTelematicInfo?.vehicleIgnitionStatus
                                ? classes.remoteOnCTA
                                : classes.remoteOffCTA,
                        ),
                        label: classes.remoteCTALabel,
                    }}
                    endIcon={
                        <IOSSwitch
                            checked={
                                evDetails?.evTelematicInfo
                                    ?.vehicleIgnitionStatus
                            }
                            onClick={null}
                            onChange={null}
                            name="checkedB"
                            className={classes.remoteSwitch}
                        />
                    }
                >
                    {!!evDetails?.evTelematicInfo?.vehicleIgnitionStatus
                        ? 'ON'
                        : 'OFF'}
                </Button>
            ),
        },
        {
            text: 'Location',
            type: SINGLE_EV_ACTION_TYPES.LOCATION,
            icon: 'location',
            show:
                !!evDetails?.trackingEnabled &&
                !!evDetails?.evTelematicInfo?.latitude &&
                !!evDetails?.evTelematicInfo?.longitude,
            component: (
                <Button
                    variant="contained"
                    onClick={() => {
                        openGoogleMaps(
                            evDetails?.evTelematicInfo?.latitude,
                            evDetails?.evTelematicInfo?.longitude,
                        );
                    }}
                    disabled={false}
                    disableElevation
                    startIcon={<NearMeIcon />}
                    className={classes.locationCTA}
                >
                    Location
                </Button>
            ),
        },
        {
            text: 'Edit Info',
            type: SINGLE_EV_ACTION_TYPES.EDIT,
            icon: 'edit',
            show: false,
            component: (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {}}
                    disabled={false}
                    disableElevation
                >
                    Edit Info
                </Button>
            ),
        },
    ];

    const getChartLabel = (key: any) => {
        let label = '';
        switch (key) {
            case 'evKm':
                label = 'EV-KM';
                break;
            case 'orderKm':
                label = 'Order-KM';
                break;
            case 'moonLight':
                label = 'Moon Lighting %';
                break;
            default:
                break;
        }
        return label;
    };
    let currentActiveRider = historyData?.evHistoryLineItems?.filter(
        (item: any) => !item.returnedDateEpochMillis,
    );
    const data =
        !!performanceData &&
        performanceData?.evPerformance &&
        !!Object.keys(performanceData?.evPerformance)?.length
            ? Object.keys(performanceData?.evPerformance).map((perm) => {
                  const performance = performanceData?.evPerformance?.[perm];
                  return {
                      name: perm,
                      'EV KM': performance?.moonLightingDetails?.totalEvKm ?? 0,
                      'Order KM':
                          performance?.moonLightingDetails?.totalOrderKm ?? 0,
                      'Moonlighting %':
                          performance?.moonLightingDetails
                              ?.moonlightingPercentage ?? 0,
                  };
              })
            : [];
    const getTabs = (rider: any) => {
        return [
            {
                label: 'Tagging History',
                icon: <EVVehicleRider />,
                content: (
                    <EVTaggingHistory
                        historyData={historyData}
                        fetchEVHistoryListData={fetchEVHistoryListData}
                    />
                ),
                disabled: false,
            },
            {
                label: 'Map view',
                icon: <EVVehicleMap />,
                content: (
                    <Box className={classes.mapContainer}>
                        {!!performanceData &&
                            performanceData?.evPerformance &&
                            !!Object.keys(performanceData?.evPerformance)
                                ?.length && (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                    mb={1}
                                >
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        minWidth={'336px'}
                                        className={classes.performBox}
                                        p={2}
                                        onClick={handlePerformClick}
                                    >
                                        <Typography
                                            className={classes.performHeader}
                                        >
                                            EV Performance
                                        </Typography>
                                        <ArrowDropDownCircleIcon
                                            color={'primary'}
                                        />
                                    </Box>
                                    {anchorEl && (
                                        <Popover
                                            id={evGoalId}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handlePerformClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                square: true,
                                                classes: {
                                                    root: classes.performBoxExtended,
                                                },
                                            }}
                                            onClick={handlePerformClose}
                                        >
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                minWidth={'100%'}
                                            >
                                                <Typography
                                                    className={
                                                        classes.performHeader
                                                    }
                                                >
                                                    EV Performance
                                                </Typography>
                                                <ArrowDropDownCircleIcon
                                                    color={'primary'}
                                                />
                                            </Box>
                                            <Divider
                                                style={{
                                                    margin: '16px 0',
                                                    backgroundColor: '#EAEDF3',
                                                }}
                                            />
                                            <Box
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    e?.preventDefault();
                                                }}
                                            >
                                                <BarChart
                                                    width={500}
                                                    height={390}
                                                    data={data}
                                                >
                                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                                    <XAxis
                                                        dataKey="name"
                                                        tickMargin={16}
                                                    />
                                                    {/* <YAxis /> */}
                                                    <Tooltip />
                                                    <Legend
                                                        verticalAlign="top"
                                                        iconSize={16}
                                                        letterSpacing={0.144}
                                                        margin={{}}
                                                        wrapperStyle={{
                                                            marginBottom: 24,
                                                        }}
                                                    />
                                                    <Bar
                                                        dataKey="EV KM"
                                                        fill="rgba(188, 187, 244, 1)"
                                                        barSize={24}
                                                        legendType="square"
                                                    />
                                                    <Bar
                                                        dataKey="Order KM"
                                                        fill="#9DEFA0"
                                                        barSize={24}
                                                        legendType="square"
                                                    />
                                                    <Bar
                                                        dataKey="Moonlighting %"
                                                        fill="#FAAC74"
                                                        barSize={24}
                                                        legendType="square"
                                                    />
                                                </BarChart>
                                            </Box>
                                        </Popover>
                                    )}
                                </Box>
                            )}
                        <RiderMapView
                            heliosTrackingData={[]}
                            staffTrackAndTrace={{
                                id: rider?.riderUserId,
                            }}
                            singleEVDetails={{
                                ...evDetails,
                                onPressMobilize: onPressMobilize,
                            }}
                        />
                    </Box>
                ),
                disabled: !rider?.riderUserId,
            },
        ];
    };
    const tabs: Array<any> = getTabs(currentRider);
    console.log(currentActiveRider);
    return (
        <Spinner loading={loading}>
            <Paper
                style={{ padding: '14px 20px' }}
                className={classes.infoContainer}
                elevation={0}
            >
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems={'center'}
                >
                    <Grid item xs={6}>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                        >
                            <ListItemIcon
                                onClick={(e) =>
                                    props?.history.push('/ev-sp', {
                                        payload:
                                            props?.location?.state?.payload,
                                        selectedFilter:
                                            props?.location?.state
                                                ?.selectedFilter,
                                    })
                                }
                                className={classes.backIcon}
                            >
                                <ArrowBackIcon
                                    style={{
                                        fontSize: '1.5rem',
                                    }}
                                />
                            </ListItemIcon>
                            <Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                >
                                    <Typography className={classes.evNumber}>
                                        {evDetails?.evNumber}
                                    </Typography>
                                    {!!evDetails?.evTelematicInfo
                                        ?.vehicleBattery && (
                                        <>
                                            <BatteryIcon
                                                percentage={
                                                    evDetails?.evTelematicInfo
                                                        ?.vehicleBattery
                                                }
                                                fontSize={17}
                                            />
                                            <Typography
                                                component={'span'}
                                                className={classes.batteryPer}
                                            >
                                                {`${evDetails?.evTelematicInfo?.vehicleBattery}% Battery`}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                {!!evDetails?.evTelematicInfo && (
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'flex-start'}
                                    >
                                        {evDetails?.evTelematicInfo
                                            ?.mobilityStatus && (
                                            <Box
                                                mr={2}
                                                p={'2px 24px'}
                                                borderRadius={8}
                                                bgcolor={'#4CAF50'}
                                                color={'#FFFFFF'}
                                                fontWeight={'bold'}
                                            >
                                                <span>{'RUNNING'}</span>
                                            </Box>
                                        )}
                                        {!!evDetails?.evTelematicInfo
                                            ?.ignitionStateChangeTime && (
                                            <Typography
                                                component={'span'}
                                                className={classes.updatedAt}
                                            >
                                                {`Updated: ${moment(
                                                    evDetails?.evTelematicInfo
                                                        ?.ignitionStateChangeTime,
                                                ).fromNow()}`}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            {CARD_ACTION_CTA?.filter(
                                (item) => !!item?.show,
                            )?.map((cta: any, index: number) => {
                                return (
                                    <React.Fragment key={`top-action-${index}`}>
                                        <Box ml={3}>{cta?.component}</Box>
                                    </React.Fragment>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.container} elevation={0}>
                <Tabbar
                    tabs={tabs}
                    tabIndex={tabIndex}
                    lastIndex={null}
                    style={{
                        justifyContent: 'flex-start',
                    }}
                    textStyle={null}
                    boxStyle={{
                        width: 'auto',
                        padding: '1rem',
                        minWidth: '136px',
                    }}
                    onChange={(index: number, isModal: any) => {
                        if (isModal) {
                            setModalTabIndex(index);
                        } else {
                            setTabIndex(index);
                            setModalTabIndex(-1);
                        }
                    }}
                />
                <Divider style={{ margin: '1rem 0' }} />
                <Box>
                    <TabTitle title={tabs?.[tabIndex]?.label} />
                    {!tabs?.[tabIndex]?.disabled &&
                    tabs?.[modalTabIndex]?.isModal
                        ? tabs?.[modalTabIndex].content
                        : null}
                    {!tabs?.[tabIndex]?.disabled
                        ? tabs?.[tabIndex]?.content
                        : null}
                </Box>
            </Paper>
            {!!confirmModalOpen && !!evDetails && (
                <ConfirmModal
                    open={confirmModalOpen}
                    data={evDetails}
                    onClose={() => {
                        setConfirmModalOpen(false);
                    }}
                    confirmationText={`remotely switch ${
                        evDetails?.evTelematicInfo?.vehicleIgnitionStatus
                            ? 'OFF'
                            : 'ON'
                    } the EV`}
                    onAction={() => {
                        setConfirmModalOpen(false);
                        onPressMobilize(evDetails);
                    }}
                />
            )}
        </Spinner>
    );
};

export default SingleEV;
