import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemSecondaryAction,
    Backdrop,
    ListItemText,
    TextField,
    Grid,
    Tab,
    Tabs,
    Menu,
    withStyles,
} from '@material-ui/core';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAlertInfoModalStyles from '../styles/useAlertInfoModalStyles';
import { CustomTextField } from '../../../components/Textfield';
import { AlertDelivery, AlertRider } from '../../../assets/svg';
import WarningIcon from '@material-ui/icons/Warning';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MapIcon from '@material-ui/icons/Map';
import { CHIPS_CLASS_TYPE_MAPPING } from '../helper';
import clsx from 'clsx';
import useControlsStyles from '../styles/useControlsStyles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import CommentModal from '../../../components/Modals/CommentModal';
import {
    ALERT_STATUS_UPDATE,
    STATUS_TYPES,
    STATUS_TYPES_CONFIG,
} from '../helper';
import { callPhoneNumber } from '../../../utils/helper';
interface AlertInfoModalProps {
    [key: string]: any;
}
const Transition: any = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const StyledMenu: any = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        transform: 'translateY(-75%) translateY(-8px) !important',
    },
})((props: any) => <Menu elevation={0} getContentAnchorEl={null} {...props} />);
const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: 'currentcolor',
    },
}))(MenuItem);
const StyledTimeline = withStyles((theme) => ({
    root: {
        color: 'currentcolor',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
}))(Timeline);
const AlertInfoModal: React.FC<AlertInfoModalProps> = ({
    alertInfo,
    open,
    onClose,
    onStatusChange,
    ...props
}) => {
    const classes: any = useAlertInfoModalStyles();
    const classesControls: any = useControlsStyles({
        status: '',
    });
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [updateAction, setUpdateAction] = useState<any>(null);
    const [isCommentOpen, toggleCommentModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (type: any) => {
        setAnchorEl(null);
    };
    const handleMenuSelect = (type: any) => {
        setAnchorEl(null);
        if (!!type) {
            setUpdateAction({
                ...STATUS_TYPES_CONFIG?.[type],
                type: type,
            });
        }
        //handleClose();
    };
    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };
    const handleCommentModalClose = () => {
        toggleCommentModal(!isCommentOpen);
        setUpdateAction(null);
    };
    const handleCommentSave = (comment: any) => {
        toggleCommentModal(!isCommentOpen);
        !!onStatusChange &&
            onStatusChange({
                id: alertInfo?.id,
                comments: comment,
                status: updateAction?.type ?? alertInfo?.status,
            });
        setUpdateAction(null);
    };

    React.useEffect(() => {
        if (!!updateAction?.name) {
            toggleCommentModal(true);
        } else {
            toggleCommentModal(false);
        }
    }, [updateAction]);

    const actions = [
        <Button
            key={2}
            variant="outlined"
            color="primary"
            disabled={!alertInfo?.latitude || alertInfo?.longitude}
            size="small"
            type="submit"
            target={'_blank'}
            href={`https://www.google.com/maps?q=${alertInfo?.latitude},${alertInfo?.longitude}`}
            classes={{
                disabled: classes.disabled,
                //root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                //backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            <MapIcon />
            View Map
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            disabled={false}
            size="small"
            type="submit"
            onClick={() => {
                setUpdateAction(STATUS_TYPES_CONFIG?.[STATUS_TYPES.COMMENT]);
            }}
            classes={{
                disabled: classes.disabled,
                //root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                //backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            Add Comment
        </Button>,
        <Fragment>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {ALERT_STATUS_UPDATE?.map((status) => {
                    return (
                        <StyledMenuItem
                            onClick={() => {
                                handleMenuSelect(status);
                            }}
                        >
                            {`Mark '${STATUS_TYPES_CONFIG?.[status]?.name}'`}
                        </StyledMenuItem>
                    );
                })}
            </StyledMenu>
            <Button
                key={2}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                disabled={false}
                size="small"
                type="submit"
                onClick={(e) => {
                    handleClick(e);
                }}
                classes={{
                    disabled: classes.disabled,
                    //root: classes.untagCTa,
                }}
                style={{
                    boxShadow: 'none',
                    //backgroundColor: '#F44336',
                    width: '171px',
                }}
                disableElevation
            >
                Change Status
            </Button>
        </Fragment>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            disabled={!alertInfo?.callNumber}
            size="small"
            type="submit"
            onClick={() => {
                if (!!alertInfo?.callNumber) {
                    //handleClose();
                    callPhoneNumber(alertInfo?.callNumber);
                }
            }}
            classes={{
                disabled: classes.disabled,
                //root: classes.untagCTa,
            }}
            style={{
                boxShadow: 'none',
                //backgroundColor: '#F44336',
                width: '171px',
            }}
            disableElevation
        >
            <CallIcon />
            Call Rider
        </Button>,
    ];
    useEffect(() => {}, []);
    let alertCounts = new Array(alertInfo?.alertCount ?? 0).fill(
        alertInfo?.alertCount ?? 0,
    );
    const handleChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };
    const classStatus: any = alertInfo?.status
        ?.toUpperCase()
        ?.replace(' ', '_')
        ?.trim();
    let chipClass: any =
        classesControls?.[`${CHIPS_CLASS_TYPE_MAPPING?.[classStatus]}`];
    console.log('The chip class', CHIPS_CLASS_TYPE_MAPPING?.[classStatus]);
    return (
        <Dialog
            onClose={(event) => {
                handleClose();
            }}
            open={isOpen}
            disableBackdropClick={true}
            TransitionComponent={Transition}
            aria-labelledby="alerts-info-dialog-slide-title"
            aria-describedby="alerts-info-dialog-slide-description"
            disableEscapeKeyDown
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'md'}
        >
            <DialogTitle
                id="alerts-info-dialog-slide-title"
                classes={{
                    root: classes.bottomBorder,
                }}
            >
                <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <>
                        <Box
                            width={'75%'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <IconButton
                                aria-label="close"
                                disabled={false}
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <ArrowBackIcon style={{ color: '#000' }} />
                            </IconButton>
                            <Box>
                                <Typography
                                    className={classes.modalTitle}
                                    variant="h6"
                                >
                                    {alertInfo?.type == 'Order Alert' ? (
                                        <IconButton
                                            className={classes.box}
                                            color="primary"
                                        >
                                            <AlertDelivery />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            className={classes.rider}
                                            color="primary"
                                        >
                                            <AlertRider />
                                        </IconButton>
                                    )}
                                    {alertInfo?.name}
                                    {` `}
                                    <Box
                                        component={'span'}
                                        className={clsx([
                                            classes.chip,
                                            chipClass,
                                        ])}
                                    >
                                        <Typography
                                            component={'span'}
                                            className={classesControls.chipText}
                                        >
                                            {alertInfo?.status ?? '-'}
                                        </Typography>
                                    </Box>
                                </Typography>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'row'}
                                >
                                    {!!alertInfo?.id && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {`${alertInfo?.id}`}
                                        </Typography>
                                    )}
                                    {alertInfo?.alertCount > 0 && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {` | `}
                                            {alertCounts?.map(() => {
                                                return (
                                                    <WarningIcon
                                                        className={
                                                            classes.alertIcon
                                                        }
                                                        key={Math.random()}
                                                    />
                                                );
                                            })}
                                        </Typography>
                                    )}
                                    {!!alertInfo?.store && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {` | ${alertInfo?.store}`}
                                        </Typography>
                                    )}
                                    {!!alertInfo?.createdAt && (
                                        <Typography
                                            className={classes.modalSubTitle}
                                            variant="h6"
                                        >
                                            {` | ${alertInfo?.createdAt}`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="close"
                            disabled={false}
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                <Box width={'100%'} pt={'0px'} pb={'16px'}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Alert Info" />
                        <Tab label="View History" />
                    </Tabs>
                    <Fragment>
                        {tabValue === 0 ? (
                            <Box mt={'40px'}>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            value={alertInfo?.type ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Type'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CustomTextField
                                            value={alertInfo?.description ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Description'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.modalTitleV2}
                                            variant="h6"
                                        >
                                            Order Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            value={alertInfo?.typeText ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Code'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            value={alertInfo?.orderStatus ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Order Status'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.modalTitleV2}
                                            variant="h6"
                                        >
                                            Rider Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            value={alertInfo?.riderName ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Name'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            value={alertInfo?.riderNumber ?? ''}
                                            classes={{}}
                                            onChange={(e: any) => {}}
                                            variant="outlined"
                                            style={{
                                                // width: '40%',
                                                marginBottom: 0,
                                            }}
                                            size="small"
                                            label={'Contact Number'}
                                            placeholder={'Enter remarks here'}
                                            disabled={true}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : (
                            <Box mt={'40px'}>
                                <StyledTimeline>
                                    {alertInfo?.history?.map(
                                        (his: any, index: number) => {
                                            return (
                                                <TimelineItem key={index}>
                                                    <TimelineOppositeContent
                                                        style={{
                                                            flex: 0,
                                                            padding: 0,
                                                        }}
                                                    ></TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot
                                                            style={{
                                                                color: 'orange',
                                                                backgroundColor:
                                                                    'orange',
                                                            }}
                                                        />
                                                        {index !=
                                                            alertInfo?.history
                                                                ?.length -
                                                                1 && (
                                                            <TimelineConnector />
                                                        )}
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <Box
                                                            bgcolor={'#F1F2FB'}
                                                            borderRadius={12}
                                                            p={'12px 24px'}
                                                            display={'flex'}
                                                            flexDirection={
                                                                'column'
                                                            }
                                                            flex={1}
                                                            width={'100%'}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                {moment(
                                                                    his?.epochMillis,
                                                                ).format(
                                                                    'MMMM Do YYYY, h:mm:ss a',
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 700,
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                {his?.name}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    textDecoration:
                                                                        'italic',
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                {`Comments: ${his?.comments}`}
                                                            </Typography>
                                                        </Box>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            );
                                        },
                                    )}
                                </StyledTimeline>
                            </Box>
                        )}
                    </Fragment>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                {actions.map((action, index) => (
                    <Box key={index}>{action}</Box>
                ))}
            </DialogActions>
            <Backdrop
                className={classes.backDropRoot}
                open={loading}
                onClick={() => {}}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {isCommentOpen && updateAction && (
                <CommentModal
                    open={isCommentOpen}
                    onClose={handleCommentModalClose}
                    data={updateAction}
                    onSave={handleCommentSave}
                />
            )}
        </Dialog>
    );
};
export default AlertInfoModal;
