import { useState, useCallback } from 'react';
import {
    CHIPS_CLASS_TYPE_MAPPING,
    EV_FILTERS_TYPES,
    openGoogleMaps,
} from './helper';
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popover,
    Switch,
    TableCell,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import useEVStyles from './useEVStyles';
import clsx from 'clsx';
import BatteryIcon from './components/BatteryIcon';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import NearMeIcon from '@material-ui/icons/NearMe';
import CustomDropdown from '../../components/Dropdown';
import CloseIcon from '@material-ui/icons/Close';
// Define the hook's return type
interface useEVTableReturn {
    getCardFilterView: Function;
    StyledTableCell: any;
    StyledTableHeadCell: any;
    StyledTableRow: any;
    getColumnComponentValue: Function;
    handleRowClick: Function;
    handleConfirmYes: Function;
    handleConfirmNo: Function;
}
export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        padding: '8px 8px',
        borderTop: '1px solid #EBEDF8',
        borderBottom: '1px solid #EBEDF8',
        '&:first-child': {
            paddingLeft: '16px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid #EBEDF8',
        },
        '&:last-child': {
            paddingRight: '16px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid #EBEDF8',
        },
    },
}))(TableCell);
export const StyledTableHeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#525050',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.144px',
        textTransform: 'capitalize',
        padding: '8px',
        borderBottom: 'none',
        //textAlign: 'center',
        minWidth: '100px',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const StyledTableRow: any = withStyles((theme) => ({
    root: {
        marginBottom: '8px',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.common.white,
        },
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
        backgroundColor: '#F8F9FA',
        cursor: 'pointer',
    },
}))(TableRow);
const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 32,
        height: 18,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 18 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useEVTable = ({
    filtered,
    history,
    evListData,
    activeFilterCard,
    setActiveFilterCard,
    onPressMobilize,
    confirmModalOpen,
    setConfirmModalOpen,
    confirmData,
    setConfirmData,
    evListingPayload,
    pagination,
}: any): useEVTableReturn => {
    console.log('thhhhhh', evListData);
    const classes: any = useEVStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState<any>(null);

    const handleTaggedUsedGoalClick = (event: any, index: number) => {
        event?.stopPropagation();
        event?.preventDefault();
        setAnchorEl(null);
        setSelectedIndex(null);
        setSelectedIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const handleTaggedUsedGoalClose = (e: any) => {
        e?.stopPropagation();
        e?.preventDefault();
        setSelectedIndex(null);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const taggedUsedGoalId = open
        ? 'simple-popover-taggedUsedGoalId'
        : undefined;

    // Memoized increment function
    const increment = useCallback(() => {}, []);

    // Memoized decrement function
    const decrement = useCallback(() => {}, []);

    const getFormattedValue = (type: any) => {
        switch (type) {
            case EV_FILTERS_TYPES.TOTAL:
                return (
                    <>
                        <Typography
                            className={classes.filterValue}
                            component={'span'}
                        >
                            {`${evListData?.taggedEv ?? 0}`}
                            <Typography
                                className={classes.filterByValue}
                                component={'span'}
                            >
                                {` / ${evListData?.totalEv ?? 0}`}
                            </Typography>
                        </Typography>
                    </>
                );
            case EV_FILTERS_TYPES.ACTIVE:
                return evListData?.activeEv ?? 0;
            case EV_FILTERS_TYPES.GPS:
                return evListData?.evGpsDetails?.enabledCount ?? 0;
            case EV_FILTERS_TYPES.GPS_OFF:
                return evListData?.evGpsDetails?.offCount ?? 0;
            case EV_FILTERS_TYPES.GPS_ON:
                return evListData?.evGpsDetails?.onCount ?? 0;
            case EV_FILTERS_TYPES.GPS_R_OFF:
                return evListData?.evGpsDetails?.remoteOffCount ?? 0;
            default:
                return '0';
        }
    };

    // Expose state and functions
    const getCardFilterView = (filter: any, totalCount: number) => {
        let content: any = <></>;
        switch (filter?.type) {
            default:
                content = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Box minWidth={'130px'}>
                            <Typography
                                className={classes.filterTitle}
                                component={'p'}
                            >
                                {filter?.name}
                            </Typography>
                            <Typography
                                className={classes.filterValue}
                                component={'p'}
                            >
                                {getFormattedValue(filter?.type)}
                            </Typography>
                        </Box>
                        {!!filter?.subFilters?.length && (
                            <Box
                                ml={2}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                <Grid container spacing={2}>
                                    {filter?.subFilters?.map(
                                        (subFilter: any, index: number) => {
                                            let isLast =
                                                index ===
                                                filter?.subFilters?.length - 1;
                                            return (
                                                <Grid
                                                    item
                                                    xs
                                                    key={`${subFilter?.type}`}
                                                >
                                                    <Box
                                                        pl={3}
                                                        pr={3}
                                                        borderLeft={
                                                            '1px solid #EBEDF8'
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.filterTitle
                                                            }
                                                            component={'p'}
                                                        >
                                                            {subFilter?.name}
                                                        </Typography>
                                                        <Typography
                                                            className={
                                                                classes.filterSubValue
                                                            }
                                                            component={'p'}
                                                        >
                                                            {getFormattedValue(
                                                                subFilter?.type,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            );
                                        },
                                    )}
                                </Grid>
                            </Box>
                        )}
                    </Box>
                );
                break;
        }
        return (
            <Grid item xs={filter?.xs ?? true} key={`${filter?.type}`}>
                <Paper
                    className={classes.filterCard}
                    elevation={0}
                    style={
                        filter?.filterValue == activeFilterCard
                            ? {
                                  backgroundColor: '#d1d8ff',
                              }
                            : {}
                    }
                    onClick={() => {
                        setActiveFilterCard(filter?.filterValue);
                    }}
                >
                    {content}
                </Paper>
            </Grid>
        );
    };

    const actionDropDown = (val: any) => {
        let options: any = [];
        const mapView = {
            item: 'Map View',
            onSuccess: (e: any) => {
                e?.stopPropagation();
                e?.preventDefault();
                if (!!val?.taggedRiderDetails?.contactNumber) {
                    handleSingleEVPage(val, 1);
                }
            },
            disabled: !val?.taggedRiderDetails?.contactNumber,
        };
        const taggingHistory = {
            item: 'Tagging History',
            onSuccess: () => {
                handleSingleEVPage(val, 0);
            },
            disabled: false,
        };
        options = [mapView, taggingHistory];
        return (
            <CustomDropdown
                title={'Options'}
                options={options}
                optionStyles={null}
                expandMoreButtonStyles={null}
                styledMenuItemStyles={classes.menu}
                buttonProps={null}
                buttonStyles={null}
            />
        );
    };
    const getColorBasedOnValue = (value: string): any => {
        console.log('trhddd', value);
        // Extract the number from the string
        const numberMatch = value?.match(/D-(\d+)/);
        if (!numberMatch) return value; // Default color if no match
        console.log('trhddd', numberMatch);

        const number = parseInt(numberMatch[1]);

        // Determine color based on the extracted number
        if (number <= 3) {
            return 'D_PENDING';
        } else if (number >= 4) {
            return 'D_REJECT';
        }
    };

    const getColumnComponentValue = (
        row: any,
        column: any,
        handleAction: any,
        index: number,
    ) => {
        let compValue: any = row?.[column?.valueKey] ?? '-/-';
        const odd = index % 2 == 0 ? false : true;

        const orderStatus: any = row?.orderStatus
            ?.toUpperCase()
            ?.replace(' ', '_')
            ?.trim();
        const evStatus: any = row?.evStatus
            ?.toUpperCase()
            ?.replace(' ', '_')
            ?.replace('-', '_')
            ?.trim()
            ?.replace(/[\s-]+/g, '_');

        let chipEVClass: any =
            classes?.[`${CHIPS_CLASS_TYPE_MAPPING?.[evStatus]}`];
        let chipOrderClass: any =
            classes?.[
                `${
                    CHIPS_CLASS_TYPE_MAPPING?.[
                        getColorBasedOnValue(orderStatus)
                    ]
                }`
            ];
        switch (column?.mappingKey) {
            case 'evDetails':
                compValue = (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Box>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                mb={0.5}
                            >
                                <Typography className={classes.evNumber}>
                                    {row?.evNumber ?? '-'}
                                </Typography>
                                {row?.remoteControlEnabled && (
                                    <Typography
                                        className={clsx(
                                            classes.vehicleIgnitionStatus,
                                            classes?.[
                                                `${
                                                    !!row?.evTelematicInfo
                                                        ?.mobiliStatus
                                                        ? 'ROFF'
                                                        : !!row?.evTelematicInfo
                                                              ?.vehicleIgnitionStatus
                                                        ? 'ON'
                                                        : 'OFF'
                                                }`
                                            ],
                                        )}
                                    >
                                        {row?.evTelematicInfo
                                            ?.vehicleIgnitionStatus
                                            ? `(${
                                                  !!row?.evTelematicInfo
                                                      ?.mobiliStatus
                                                      ? 'Roff'
                                                      : !!row?.evTelematicInfo
                                                            ?.vehicleIgnitionStatus
                                                      ? 'On'
                                                      : 'Off'
                                              })`
                                            : 'Off'}
                                    </Typography>
                                )}
                            </Box>
                            <Box display={'flex'} flexDirection={'row'}>
                                <Typography className={classes.evVendor}>
                                    {row?.vendor ?? '-'}
                                </Typography>
                                {!!row?.evTelematicInfo?.vehicleBattery && (
                                    <>
                                        <BatteryIcon
                                            percentage={
                                                row?.evTelematicInfo
                                                    ?.vehicleBattery
                                            }
                                            fontSize={17}
                                        />
                                        <Typography
                                            className={classes.evVendor}
                                            component={'span'}
                                        >
                                            <Typography component={'span'}>
                                                {`${row?.evTelematicInfo?.vehicleBattery}% battery`}
                                            </Typography>
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                        {
                            <Box ml={3} display={'flex'} alignItems={'center'}>
                                {!!row?.trackingEnabled &&
                                    !!row?.evTelematicInfo?.latitude &&
                                    !!row?.evTelematicInfo?.longitude && (
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            className={classes.directionCTA}
                                            onClick={(e: any) => {
                                                e?.stopPropagation();
                                                e?.preventDefault();
                                                openGoogleMaps(
                                                    row?.evTelematicInfo
                                                        ?.latitude,
                                                    row?.evTelematicInfo
                                                        ?.longitude,
                                                );
                                            }}
                                        >
                                            <NearMeIcon
                                                className={
                                                    classes.directionIcon
                                                }
                                            />
                                        </IconButton>
                                    )}
                                {row?.remoteControlEnabled && (
                                    <IOSSwitch
                                        checked={
                                            row?.evTelematicInfo
                                                ?.vehicleIgnitionStatus
                                        }
                                        onClick={(e: any) => {
                                            e?.stopPropagation();
                                            e?.preventDefault();
                                        }}
                                        onChange={(e: any) => {
                                            e?.stopPropagation();
                                            e?.preventDefault();
                                            setConfirmData({
                                                ...row,
                                                confirmText: `remotely switch ${
                                                    row?.evTelematicInfo
                                                        ?.vehicleIgnitionStatus
                                                        ? 'OFF'
                                                        : 'ON'
                                                } the EV`,
                                            });
                                            setConfirmModalOpen(true);
                                        }}
                                        name="checkedB"
                                        className={classes.remoteSwitch}
                                    />
                                )}
                            </Box>
                        }
                    </Box>
                );
                break;
            case 'riderDetails':
                compValue = (
                    <Box pl={3}>
                        <Typography className={classes.tableValue}>
                            {row?.taggedRiderDetails?.name ?? '-'}
                        </Typography>
                        <Typography className={classes.tableSubValue}>
                            {row?.taggedRiderDetails?.contactNumber ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'taggedUsedGoal':
                compValue = (
                    <div>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className={classes.taggedUsedGoal}
                            onClick={(e: any) => {
                                if (
                                    !!row?.evProductivity?.taggingDetails ||
                                    !!row?.evProductivity?.usagesDetails ||
                                    !!row?.evProductivity?.goalDetails
                                ) {
                                    handleTaggedUsedGoalClick(e, index);
                                }
                            }}
                            //bgcolor={!!odd ? '#fff' : '#EBEDF8'}
                        >
                            <Typography
                                component={'span'}
                                className={
                                    row?.evProductivity?.taggingDetails
                                        ?.taggedPercentage > 25
                                        ? classes.tableValue
                                        : classes.tableValueInRed
                                }
                            >
                                {row?.evProductivity?.taggingDetails
                                    ?.taggedPercentage != null
                                    ? `${row?.evProductivity?.taggingDetails?.taggedPercentage}%`
                                    : '-'}
                            </Typography>
                            <Typography
                                component={'span'}
                                className={classes.tableValue}
                                style={{
                                    padding: '0 6px',
                                }}
                            >
                                |
                            </Typography>
                            <Typography
                                component={'span'}
                                className={
                                    row?.evProductivity?.usagesDetails
                                        ?.usedPercentage > 25
                                        ? classes.tableValue
                                        : classes.tableValueInRed
                                }
                            >
                                {row?.evProductivity?.usagesDetails
                                    ?.usedPercentage != null
                                    ? `${row?.evProductivity?.usagesDetails?.usedPercentage}%`
                                    : '-'}
                            </Typography>
                            <Typography
                                component={'span'}
                                className={classes.tableValue}
                                style={{
                                    padding: '0 6px',
                                }}
                            >
                                |
                            </Typography>
                            <Typography
                                component={'span'}
                                className={
                                    row?.evProductivity?.goalDetails
                                        ?.goalPercentage > 25
                                        ? classes.tableValue
                                        : classes.tableValueInRed
                                }
                            >
                                {row?.evProductivity?.goalDetails
                                    ?.goalPercentage != null
                                    ? `${row?.evProductivity?.goalDetails?.goalPercentage}%`
                                    : '-'}
                            </Typography>
                        </Box>
                        {anchorEl && index == selectedIndex && (
                            <Popover
                                id={taggedUsedGoalId}
                                open={anchorEl && index == selectedIndex}
                                anchorEl={anchorEl}
                                onClose={handleTaggedUsedGoalClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                PaperProps={{
                                    square: true,
                                    classes: {
                                        root: classes.popOver,
                                    },
                                    onClick: (e) => {
                                        e?.stopPropagation();
                                        e?.preventDefault();
                                    },
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    //alignItems={'center'}
                                    justifyContent={'space-between'}
                                    onClick={(e) => {
                                        e?.stopPropagation();
                                        e?.preventDefault();
                                    }}
                                >
                                    <Box flex={1}>
                                        <Typography
                                            className={classes.tableValue}
                                        >
                                            EV Performance summary
                                        </Typography>
                                        <Typography
                                            className={classes.tableSubValue}
                                        >
                                            {row?.evNumber ?? '-'}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        aria-label="close popover"
                                        onClick={handleTaggedUsedGoalClose}
                                        className={classes.closeCTA}
                                    >
                                        <CloseIcon
                                            className={classes.closeIcon}
                                        />
                                    </IconButton>
                                </Box>
                                <Divider
                                    style={{
                                        margin: '16px 0',
                                        backgroundColor: '#EAEDF3',
                                    }}
                                />
                                <Box flexGrow={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs>
                                            <Paper
                                                className={classes.permCard}
                                                elevation={0}
                                            >
                                                <Typography
                                                    className={
                                                        classes.permTitle
                                                    }
                                                    component={'p'}
                                                >
                                                    {'Tagged'}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.permValue
                                                    }
                                                    component={'p'}
                                                >
                                                    {`${
                                                        row?.evProductivity
                                                            ?.taggingDetails
                                                            ?.taggedDays ?? '-'
                                                    } / ${
                                                        row?.evProductivity
                                                            ?.taggingDetails
                                                            ?.totalNoOfDays +
                                                            ' days' ?? '-'
                                                    }`}
                                                </Typography>
                                                {!!row?.evProductivity
                                                    ?.taggingDetails
                                                    ?.taggedPercentage && (
                                                    <Typography
                                                        component={'p'}
                                                        className={
                                                            row?.evProductivity
                                                                ?.taggingDetails
                                                                ?.taggedPercentage >
                                                            25
                                                                ? classes.permSubValue
                                                                : classes.tableSubValueRed
                                                        }
                                                    >
                                                        {row?.evProductivity
                                                            ?.taggingDetails
                                                            ?.taggedPercentage
                                                            ? `${
                                                                  row
                                                                      ?.evProductivity
                                                                      ?.taggingDetails
                                                                      ?.taggedPercentage ??
                                                                  0
                                                              }%`
                                                            : '-'}
                                                    </Typography>
                                                )}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs>
                                            <Paper
                                                className={classes.permCard}
                                                elevation={0}
                                            >
                                                <Typography
                                                    className={
                                                        classes.permTitle
                                                    }
                                                    component={'p'}
                                                >
                                                    {'Usage'}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.permValue
                                                    }
                                                    component={'p'}
                                                >
                                                    {`${
                                                        row?.evProductivity
                                                            ?.usagesDetails
                                                            ?.usedDays ?? '-'
                                                    } / ${
                                                        row?.evProductivity
                                                            ?.usagesDetails
                                                            ?.totalNoOfDays +
                                                            ' days' ?? '-'
                                                    }`}
                                                </Typography>
                                                {!!row?.evProductivity
                                                    ?.usagesDetails
                                                    ?.usedPercentage && (
                                                    <Typography
                                                        component={'p'}
                                                        className={
                                                            row?.evProductivity
                                                                ?.usagesDetails
                                                                ?.usedPercentage >
                                                            25
                                                                ? classes.permSubValue
                                                                : classes.tableSubValueRed
                                                        }
                                                    >
                                                        {row?.evProductivity
                                                            ?.usagesDetails
                                                            ?.usedPercentage
                                                            ? `${
                                                                  row
                                                                      ?.evProductivity
                                                                      ?.usagesDetails
                                                                      ?.usedPercentage ??
                                                                  0
                                                              }%`
                                                            : '-'}
                                                    </Typography>
                                                )}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs>
                                            <Paper
                                                className={clsx(
                                                    classes.permCard,
                                                    classes.permCardRed,
                                                )}
                                                elevation={0}
                                            >
                                                <Typography
                                                    className={
                                                        classes.permTitle
                                                    }
                                                    component={'p'}
                                                >
                                                    {'Order KM Goal achieved'}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.permValue
                                                    }
                                                    component={'p'}
                                                >
                                                    {`${
                                                        row?.evProductivity
                                                            ?.goalDetails
                                                            ?.goalAchievedDays ??
                                                        '-'
                                                    } / ${
                                                        row?.evProductivity
                                                            ?.goalDetails
                                                            ?.totalNoOfDays +
                                                            ' days' ?? '-'
                                                    }`}
                                                </Typography>
                                                {!!row?.evProductivity
                                                    ?.goalDetails
                                                    ?.goalPercentage && (
                                                    <Typography
                                                        component={'p'}
                                                        className={
                                                            row?.evProductivity
                                                                ?.goalDetails
                                                                ?.goalPercentage >
                                                            25
                                                                ? classes.permSubValue
                                                                : classes.tableSubValueRed
                                                        }
                                                    >
                                                        {row?.evProductivity
                                                            ?.goalDetails
                                                            ?.goalPercentage
                                                            ? `${
                                                                  row
                                                                      ?.evProductivity
                                                                      ?.goalDetails
                                                                      ?.goalPercentage ??
                                                                  0
                                                              }%`
                                                            : '-'}
                                                    </Typography>
                                                )}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs>
                                            <Paper
                                                className={clsx(
                                                    classes.permCard,
                                                    classes.permCardRed,
                                                )}
                                                elevation={0}
                                            >
                                                <Typography
                                                    className={
                                                        classes.permTitle
                                                    }
                                                    component={'p'}
                                                >
                                                    {'Moonlighting %'}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.permValue
                                                    }
                                                    component={'p'}
                                                >
                                                    {`${
                                                        row?.evProductivity
                                                            ?.moonLightingDetails
                                                            ?.totalOrderKm ??
                                                        '-'
                                                    } Km / ${
                                                        row?.evProductivity
                                                            ?.moonLightingDetails
                                                            ?.totalEvKm ?? '-'
                                                    } Km (Order / EV)`}
                                                </Typography>
                                                {!!row?.evProductivity
                                                    ?.moonLightingDetails
                                                    ?.moonlightingPercentage && (
                                                    <Typography
                                                        component={'p'}
                                                        className={
                                                            row?.evProductivity
                                                                ?.moonLightingDetails
                                                                ?.moonlightingPercentage >
                                                            25
                                                                ? classes.permSubValue
                                                                : classes.tableSubValueRed
                                                        }
                                                    >
                                                        {row?.evProductivity
                                                            ?.moonLightingDetails
                                                            ?.moonlightingPercentage
                                                            ? `${
                                                                  row
                                                                      ?.evProductivity
                                                                      ?.moonLightingDetails
                                                                      ?.moonlightingPercentage ??
                                                                  0
                                                              }%`
                                                            : '-'}
                                                    </Typography>
                                                )}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popover>
                        )}
                    </div>
                );
                break;
            case 'city':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.city ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'lob':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {row?.taggedProject ?? '-'}
                        </Typography>
                    </Box>
                );
                break;
            case 'orderStatus':
                compValue = !!row?.orderStatus ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Box className={clsx([classes.chip, chipOrderClass])}>
                            <Typography
                                component={'span'}
                                className={classes.chipText}
                            >
                                {row?.orderStatus ?? '-'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    '-'
                );
                break;
            case 'evStatus':
                compValue = !!row?.evStatus ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                    >
                        <Box className={clsx([classes.chip, chipEVClass])}>
                            <Typography
                                component={'span'}
                                className={classes.chipText}
                            >
                                {row?.evStatus?.replace(/[-]+/g, ' ')?.trim() ??
                                    '-'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    '-'
                );
                break;
            case 'action':
                compValue = (
                    <Box>
                        <Typography className={classes.tableValue}>
                            {actionDropDown(row)}
                        </Typography>
                    </Box>
                );
                break;

            default:
                break;
        }
        return compValue;
    };
    // Notification Function
    const sendNotification = (message: string) => {
        if (Notification.permission === 'granted') {
            new Notification(message);
        }
    };
    const handleRowClick = (e: any, val: any, index: number) => {
        handleSingleEVPage(val, 0);
    };
    const handleConfirmYes = (data: any) => {
        setConfirmData(null);
        setConfirmModalOpen(false);
        onPressMobilize(data);
    };
    const handleConfirmNo = () => {
        setConfirmData(null);
        setConfirmModalOpen(false);
    };
    const handleSingleEVPage = (ev: any, tab: number) => {
        let temp = {
            filteredValues: {
                ...JSON.parse(JSON.stringify(filtered)),
            },
            pagination: {
                ...JSON.parse(JSON.stringify(pagination)),
            },
            evListingPayload: {
                ...JSON.parse(JSON.stringify(evListingPayload)),
            },
            activeFilterCard: activeFilterCard,
        };
        history?.push(`/ev-sp/details/${ev?.evNumber}`, {
            payload: ev,
            selectedTab: tab,
            selectedFilter: temp,
        });
    };
    return {
        getCardFilterView,
        StyledTableCell,
        StyledTableHeadCell,
        StyledTableRow,
        getColumnComponentValue,
        handleRowClick,
        handleConfirmYes,
        handleConfirmNo,
    };
};
export default useEVTable;
