import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { connect } from 'react-redux';
import Searchbar from '../Searchbar';
import {
    ChangeOrg,
    GetOrganisations,
    GetOrgs,
} from '../../store/actions/settingsActionCreator';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { fetchAppConfig } from '../../store/actions/authActionCreator';

function SwitchHubModal({
    dispatchGetOrgs,
    dispatchChangeOrg,
    dispatchFetchAppConfig,
    dispatchGetOrganisations,
    orgList,
    open,
    onClose,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(true);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [searchedValue, setSearchedValue] = useState('');

    useEffect(() => {
        console.log(props);
        fetchOrgsList();
    }, []);

    const fetchOrgsList = () => {
        dispatchGetOrgs(
            (resp) => {
                toggleLoading(false);
            },
            (error) => {
                console.log(error);
                toggleLoading(false);
            },
        );
    };

    const handleClose = () => {
        toggleModal(!isOpen);
        onClose && onClose();
    };

    const changeOrg = (id) => {
        toggleLoading(true);
        dispatchChangeOrg(
            id,
            (resp) => {
                localStorage.setItem(
                    'org',
                    orgList.find((item) => item.id === id).name,
                );
                dispatchGetOrganisations(
                    id,
                    (resp) => {
                        dispatchFetchAppConfig((resp) => {
                            handleClose();
                            toggleLoading(false);
                            if (
                                props.location.pathname.includes(
                                    '/staff/details/',
                                )
                            ) {
                                window.location.href = '/staff';
                            } else {
                                window.location.reload();
                            }
                        });
                    },
                    (err) => {
                        toggleLoading(false);
                    },
                );
            },
            (err) => {
                toggleLoading(false);
            },
        );
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={'Switch Branches'}
            loading={loading}
            multiOrgModal={true}
        >
            <Box
                style={{
                    width: '100%',
                    top: '70px',
                    height: '40px',
                    overflowY: 'hidden',
                    position: 'sticky',
                    background: 'white',
                    zIndex: 999,
                }}
            >
                <Searchbar
                    placeHolderText="Search Company Name"
                    value={searchedValue}
                    keys={['name', 'hubName']}
                    onFilter={async (filteredData) => {}}
                    onChange={(value) => {
                        console.log('blur', value);
                        setSearchedValue(value);
                    }}
                />
            </Box>
            <List dense={true}>
                {orgList
                    ?.filter((org) =>
                        org.name
                            ?.toLowerCase()
                            .includes(searchedValue?.toLowerCase()),
                    )
                    ?.map((org, orgIndex) => {
                        return (
                            <ListItem
                                button
                                component="a"
                                key={orgIndex}
                                style={{
                                    borderRadius: 8,
                                    border: 'solid 1px #ebedf8',
                                    backgroundColor: '#f8f9fa',
                                    marginTop: 8,
                                }}
                                onClick={(e) => changeOrg(org.id)}
                            >
                                <ListItemText
                                    primary={org.name}
                                    primaryTypographyProps={{
                                        style: {
                                            fontFamily: 'Nunito',
                                            fontSize: 16,
                                            fontWeight: 600,
                                        },
                                    }}
                                    secondaryTypographyProps={{
                                        style: {
                                            fontFamily: 'Nunito',
                                            fontSize: 14,
                                            fontWeight: 'regular',
                                        },
                                    }}
                                    secondary={`${org.staffCount} employees`}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <ChevronRightIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
            </List>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    orgList: state.settings.orgs,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchGetOrgs: (onSuccess, onError) =>
        dispatch(GetOrgs(onSuccess, onError)),
    dispatchChangeOrg: (id, onSuccess, onError) =>
        dispatch(ChangeOrg(id, onSuccess, onError)),
    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
    dispatchGetOrganisations: (id, onSuccess, onError) =>
        dispatch(GetOrganisations(id, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchHubModal);
