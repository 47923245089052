import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    IconButton,
} from '@material-ui/core';
import useControlsStyles from '../styles/useControlsStyles';
import { getControlsTabProps, CONTROL_TYPES } from '../helper';
import Orders from './Orders';
import Riders from './Riders';
import Alerts from './Alerts';
import useControlTable from './useControlTable';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const ControlTabs = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
        color: '#7C8791',
    },
    indicator: {
        backgroundColor: '#3B5A98',
        height: 3,
    },
})(Tabs);

const ControlTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        //minWidth: 72,
        fontWeight: 600,
        marginRight: 0,
        fontSize: 16,
        color: '#7C8791',
        fontFamily: ['Nunito'].join(','),
        '&:hover': {
            color: '#4D4AB5',
            opacity: 1,
        },
        '&$selected': {
            color: '#4D4AB5',
            fontWeight: 700,
        },
        '&:focus': {
            color: '#4D4AB5',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
function ControlTabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`control-center-tab-${index}`}
            aria-labelledby={`control-center-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}
interface ControlsProps {
    [key: string]: any;
    tabs: Array<any>;
}

const Controls: React.FC<ControlsProps> = ({
    tabs,
    handleLoading,
    ...props
}) => {
    const classes = useControlsStyles();
    const [lastUpdated, setLastUpdated] = useState<any | null>({});

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: any) => {
        setValue(index);
    };

    const getContent = (tab: any) => {
        switch (tab?.type) {
            case CONTROL_TYPES.ORDERS:
                return (
                    <Orders
                        handleLoading={handleLoading}
                        controlType={tab?.type}
                        lastUpdated={lastUpdated}
                        setLastUpdated={setLastUpdated}
                    />
                );
            case CONTROL_TYPES.RIDERS:
                return (
                    <Riders
                        handleLoading={handleLoading}
                        controlType={tab?.type}
                        lastUpdated={lastUpdated}
                        setLastUpdated={setLastUpdated}
                    />
                );
            case CONTROL_TYPES.ALERTS:
                return (
                    <Alerts
                        handleLoading={handleLoading}
                        controlType={tab?.type}
                        lastUpdated={lastUpdated}
                        setLastUpdated={setLastUpdated}
                    />
                );
            case CONTROL_TYPES.MAP_VIEW:
                break;
            default:
                break;
        }
    };
    const filtered = tabs?.filter((tab: any) => !!tab?.show);
    return !!filtered?.length ? (
        <div className={classes.root}>
            <AppBar
                position="static"
                color="inherit"
                style={{ boxShadow: 'none' }}
            >
                <ControlTabs
                    value={value}
                    onChange={handleChange}
                    //indicatorColor="primary"
                    //textColor="primary"
                    //variant="fullWidth"
                    aria-label="Control Center"
                >
                    {filtered?.map((tab: any, index: any) => {
                        return (
                            <ControlTab
                                key={index}
                                {...getControlsTabProps(index, tab)}
                            />
                        );
                    })}
                    {!!lastUpdated?.[filtered?.[value]?.type] && (
                        <Box
                            ml={'auto'}
                            alignItems={'center'}
                            p={'12px'}
                            display={'flex'}
                            flexDirection={'row'}
                        >
                            <FiberManualRecordIcon
                                className={classes.filterLastUpdated}
                            />
                            <Typography className={classes.filterLastTime}>
                                {`Last Updated : ${
                                    lastUpdated?.[filtered?.[value]?.type] ??
                                    '-/-'
                                }`}
                            </Typography>
                        </Box>
                    )}
                </ControlTabs>
            </AppBar>
            {filtered?.map((tab: any, index: any) => {
                return (
                    <ControlTabPanel value={value} index={index}>
                        {getContent(tab)}
                    </ControlTabPanel>
                );
            })}
        </div>
    ) : (
        <></>
    );
};

export default Controls;
