import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    Paper,
    IconButton,
    Grid,
    TablePagination,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Button,
} from '@material-ui/core';
import useControlsStyles from '../../styles/useControlsStyles';
import {
    getControlsTabProps,
    CONTROL_TYPES,
    preProcessThePayload,
} from '../../helper';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../../../components/Modals/FilterModal';
import {
    CONTROL_ORDERS_FILTERS,
    CONTROL_ALERTS_FILTER_CARDS,
} from '../../helper';
import useControlTable from '../useControlTable';
import Searchbar from '../../../../components/Searchbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import AlertInfoModal from '../AlertInfoModal';
import { callPhoneNumber } from '../../../../utils/helper';
import { usePolling } from '../../../../hooks/usePolling';

interface AlertsProps {
    [key: string]: any;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        padding: '8px 8px',
        borderTop: '1px solid #EBEDF8',
        borderBottom: '1px solid #EBEDF8',
        '&:first-child': {
            paddingLeft: '16px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid #EBEDF8',
        },
        '&:last-child': {
            paddingRight: '16px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid #EBEDF8',
        },
    },
}))(TableCell);
const StyledTableHeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#525050',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.144px',
        textTransform: 'capitalize',
        padding: '8px',
        borderBottom: 'none',
        //textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow: any = withStyles((theme) => ({
    root: {
        marginBottom: '8px',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.common.white,
        },
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
    },
}))(TableRow);

const Alerts: React.FC<AlertsProps> = ({
    tabs,
    handleLoading,
    controlType,
    lastUpdated,
    setLastUpdated,

    ...props
}) => {
    //State Values
    const {
        alertsTable,
        getColumnComponentValue,
        prepareFilterOptionsAlerts,
        filteredAlertsValues,
        setFilteredAlertsValue,
        getAllAlertsInfo,
        alerts,
        updateAlertsStatus,
        configs,
        staff,
    } = useControlTable({
        handleLoading,
        controlType,
        lastUpdated,
        setLastUpdated,
    });
    const [isPolling, setIsPolling] = useState<boolean>(false); // State to control polling

    const [rendered, setRendered] = React.useState(false);
    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 25,
    );
    const [activeFilterCard, setActiveFilterCard] = useState<any>({
        type: 'Total',
        subType: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [listingPayload, setListingPayload] = React.useState({
        filters: activeFilterCard,
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        hubCodes: staff?.hubs?.[0]?.id
            ? [
                  {
                      label: staff?.hubs?.[0]?.name,
                      value: staff?.hubs?.[0]?.id,
                      storeId: staff?.hubs?.[0]?.hyperlocalStoreId ?? 1018,
                  },
              ]
            : [],
    });

    //Hooks
    const classes = useControlsStyles();
    const theme = useTheme();

    React.useEffect(() => {
        setRendered(true);
        setIsPolling(true);
    }, []);
    const prepareFilterCard = (card: any) => {
        switch (card?.type) {
            case CONTROL_ORDERS_FILTERS.RESOLVED:
                return (
                    <Box>
                        <Typography className={classes.cardHeader}>
                            {card?.name}
                            <Typography
                                component={'span'}
                                className={classes.cardSubHeaderH}
                            >
                                {alerts?.[card?.valueMappingKey] ?? '0'}
                            </Typography>
                        </Typography>
                        {!!card?.subFilters?.length && (
                            <Box
                                //mt={'8px'}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                {card?.subFilters?.map(
                                    (subFilter: any, index: number) => {
                                        return (
                                            <Box
                                                key={
                                                    subFilter?.filterValue
                                                        ?.subType
                                                }
                                                className={
                                                    classes.smallValueBox
                                                }
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                style={
                                                    activeFilterCard?.subType ==
                                                        subFilter?.filterValue
                                                            ?.subType &&
                                                    subFilter?.filterValue
                                                        ?.type ==
                                                        activeFilterCard?.type
                                                        ? {
                                                              backgroundColor:
                                                                  '#D1D8FF',
                                                          }
                                                        : {}
                                                }
                                                onClick={(e) => {
                                                    e?.preventDefault();
                                                    e?.stopPropagation();
                                                    setActiveFilterCard(
                                                        subFilter?.filterValue,
                                                    );
                                                    setListingPayload(
                                                        (prev) => {
                                                            return {
                                                                ...prev,
                                                                pageNo: 1,
                                                                filters:
                                                                    subFilter?.filterValue,
                                                            };
                                                        },
                                                    );
                                                    setPage(0);
                                                }}
                                            >
                                                <Typography
                                                    className={
                                                        classes.cardHeaderXs
                                                    }
                                                >
                                                    {alerts?.[
                                                        card?.subValueMappingKey
                                                    ]?.[
                                                        subFilter
                                                            ?.valueMappingKey
                                                    ] ?? '0'}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.cardSubHeaderXs
                                                    }
                                                >
                                                    {subFilter.name}
                                                </Typography>
                                            </Box>
                                        );
                                    },
                                )}
                            </Box>
                        )}
                    </Box>
                );
            default:
                return (
                    <>
                        <Typography className={classes.cardHeader}>
                            {card?.name}
                        </Typography>
                        <Typography className={classes.cardSubHeader}>
                            {alerts?.[card?.valueMappingKey] ?? '0'}
                        </Typography>
                    </>
                );
        }
    };
    const handleChangePage = (event?: any, newPage?: any) => {
        setPage(newPage);
        setListingPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };
    const handleChangeRowsPerPage = (event?: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event?.target?.value,
            };
        });
    };
    const handleAction = (type: any, row: any) => {
        if (type == 'more') {
            setAlertInfo(row);
            setModalOpen(true);
        } else if (type == 'call') {
            if (!!row?.callNumber) {
                //handleClose();
                callPhoneNumber(row?.callNumber);
            }
        }
    };
    const fetchAlerts = (payload: any) => {
        getAllAlertsInfo(preProcessThePayload(payload));
    };
    React.useEffect(() => {
        fetchAlerts(listingPayload);
    }, [listingPayload]);

    const rows: any = alerts?.lineItems;
    React.useEffect(() => {
        if (rendered) {
            setListingPayload((prev) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchVal,
                };
            });
            setPage(0);
        }
    }, [searchVal]);
    // the custom hook for polling
    usePolling(
        async () => {
            fetchAlerts(listingPayload);
        },
        configs?.pollingTimeOut ?? 15000,
        isPolling,
        false,
    );
    return (
        <Box>
            <Box className={classes.filterContainer}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    flex={1}
                >
                    <FilterModal
                        disabled={false}
                        filterOptions={prepareFilterOptionsAlerts()}
                        filteredValues={filteredAlertsValues}
                        onClose={null}
                        onRemoveAll={null}
                        onFilterApplied={() => {
                            setListingPayload((prev) => {
                                return {
                                    ...prev,
                                    pageNo: 1,
                                    ...filteredAlertsValues,
                                };
                            });
                            setPage(0);
                        }}
                    />
                    <FilteredState
                        filterOptions={prepareFilterOptionsAlerts()}
                        filteredValues={filteredAlertsValues}
                        onDelete={(filterKey: any) => {
                            let newFilteredValues = { ...filteredAlertsValues };
                            delete newFilteredValues?.[filterKey];
                            let newPayload: any = { ...listingPayload };
                            delete newPayload?.[filterKey];
                            setFilteredAlertsValue(newFilteredValues);
                            setListingPayload({
                                pageNo: 1,
                                ...newPayload,
                            });
                            setPage(0);
                        }}
                        count={null}
                        selectInfo={null}
                        selected={null}
                    />
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {CONTROL_ALERTS_FILTER_CARDS.map(
                            (value: any, index: number) => (
                                <Grid
                                    key={index}
                                    xs={value?.xs ? value?.xs : true}
                                    item
                                    style={{
                                        maxWidth: 'fit-content',
                                    }}
                                >
                                    <Paper
                                        className={classes.filterBox}
                                        style={
                                            value?.filterValue?.type ==
                                                activeFilterCard?.type &&
                                            !activeFilterCard?.subType
                                                ? {
                                                      backgroundColor:
                                                          '#D1D8FF',
                                                  }
                                                : {}
                                        }
                                        onClick={() => {
                                            setActiveFilterCard(
                                                value?.filterValue ?? {},
                                            );
                                            setListingPayload((prev) => {
                                                return {
                                                    ...prev,
                                                    pageNo: 1,
                                                    filters:
                                                        value?.filterValue ??
                                                        {},
                                                };
                                            });
                                            setPage(0);
                                        }}
                                    >
                                        {prepareFilterCard(value)}
                                    </Paper>
                                </Grid>
                            ),
                        )}
                    </Grid>
                </Box>
            </Box>
            <Divider style={{ margin: '12px 0', marginBottom: '16px' }} />
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={'8px'}
            >
                <Searchbar
                    placeHolderText="Search by Name, Hub Name, Contact No."
                    // placeHolderStyles={{
                    //     color: '#979797',
                    // }}
                    style={{
                        maxWidth: '350px',
                    }}
                    dataSource={null}
                    backendSearchbar={true}
                    onBackendSearch={(searchText: any) => {
                        setPage(0);
                        setSearchVal(searchText);
                    }}
                    value={searchVal}
                    keys={['name', 'hubName']}
                    onFilter={async (filteredData: any) => {}}
                    onChange={(value: any) => {
                        console.log('blur', value);
                        setSearchVal(value);
                    }}
                />

                <Box>
                    {/* <Tooltip title="Download" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {}}
                            disabled={false}
                        >
                            {`Download  `}
                            <GetAppIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                />
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Refresh" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {
                                fetchAlerts(listingPayload);
                            }}
                            disabled={false}
                        >
                            {`Refresh  `}
                            {/* <CachedIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                /> */}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            {!!rows?.length ? (
                <TableContainer
                    component={Box}
                    className={classes.tableContainerAlerts}
                >
                    <Table stickyHeader aria-label="Alerts table">
                        <TableHead>
                            <TableRow>
                                {alertsTable?.map((column: any, index: any) => {
                                    return (
                                        <StyledTableHeadCell
                                            key={`column${index}`}
                                        >
                                            {column?.name}
                                        </StyledTableHeadCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row: any) => (
                                <>
                                    <StyledTableRow key={row.name}>
                                        {alertsTable?.map(
                                            (column: any, index: any) => {
                                                return (
                                                    <StyledTableCell
                                                        key={`row${index}`}
                                                    >
                                                        {getColumnComponentValue(
                                                            row,
                                                            column,
                                                            handleAction,
                                                        )}
                                                    </StyledTableCell>
                                                );
                                            },
                                        )}
                                    </StyledTableRow>
                                    <Divider
                                        style={{
                                            margin: '4px 0',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    mt={3}
                >
                    <Typography
                        component={'p'}
                        style={{
                            color: '#ED0000',
                            fontWeight: 700,
                        }}
                    >
                        No data found. Please retry filters and try again.
                    </Typography>
                </Box>
            )}
            <Box className={classes.paginationContainer}>
                {(!!alerts?.totalFilteredCount || !!alerts?.totalCount) && (
                    <TablePagination
                        rowsPerPageOptions={
                            alerts?.totalFilteredCount > 25 ||
                            alerts?.totalCount > 25
                                ? [25, 50, 75, 100]
                                : []
                        }
                        component="div"
                        count={alerts?.totalFilteredCount ?? alerts?.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Box>
            {modalOpen && (
                <AlertInfoModal
                    open={modalOpen}
                    alertInfo={alertInfo}
                    onStatusChange={(status: any) => {
                        setModalOpen(false);
                        updateAlertsStatus(status);
                        setListingPayload((prev) => {
                            return {
                                ...prev,
                            };
                        });
                    }}
                    onClose={() => {
                        setModalOpen(false);
                        getAllAlertsInfo(listingPayload);
                    }}
                />
            )}
        </Box>
    );
};

export default Alerts;
